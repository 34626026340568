import Header from './components/Header';
import OffersTable from './components/OffersTable';
import DetailsView from './components/DetailsView';
import Footer from './components/Footer';
import MarketExplorerView from './components/MarketExplorerView';
import AllOffersView from './components/AllOffersView';

import erc20Abi from "./erc20Abi";

import './components/App.css';

import YamABIArray from './YamABI.json';

import React, { useCallback, useState, useMemo, useEffect, useRef } from 'react';
import { GraphQLClient } from 'graphql-request';
// Removed: import Papa from 'papaparse';

const Web3 = require("web3");

function App() {
  const [items, setItems] = useState([]);
  const [currentBlockNumber, setCurrentBlockNumber] = useState(null);
  const [yamOffersLast24Hours, setYamOffersLast24Hours] = useState(0);
  const [processedTransactions, setProcessedTransactions] = useState(new Set());
  const isInitiatedRef = useRef(false);
  const hasFetchedPastEventsRef = useRef(false);
  const [soundOn, setSoundOn] = useState(true);
  const [selectedTokenAddress, setselectedTokenAddress] = useState(null);
  const [sellerOffersData, setSellerOffersData] = useState(null);
  const [activeView, setActiveView] = useState('liveMarket');

  // Data structure to hold the JSON data
  const [realTokenPropertiesData, setRealTokenPropertiesData] = useState([]);
  const [dataFetched, setDataFetched] = useState(false);

  // Updated lookup functions to use uuid instead of a contract address field

  const getShortNameFromCSV = useCallback(
    (offerTokenAddress) => {
      const lowerCaseOfferTokenAddress = offerTokenAddress.toLowerCase();
      const matchingRow = realTokenPropertiesData.find(
        (row) => row.uuid && row.uuid.toLowerCase() === lowerCaseOfferTokenAddress
      );
      return matchingRow ? matchingRow.shortName : "Not Found";
    },
    [realTokenPropertiesData]
  );

  const getTokenPriceFromCSV = useCallback(
    (offerTokenAddress) => {
      const lowerCaseOfferTokenAddress = offerTokenAddress.toLowerCase();
      const matchingRow = realTokenPropertiesData.find(
        (row) => row.uuid && row.uuid.toLowerCase() === lowerCaseOfferTokenAddress
      );
      return matchingRow ? parseFloat(matchingRow.tokenPrice) : NaN;
    },
    [realTokenPropertiesData]
  );

  const getNetRentYearPerTokenFromCSV = useCallback(
    (offerTokenAddress) => {
      const lowerCaseOfferTokenAddress = offerTokenAddress.toLowerCase();
      const matchingRow = realTokenPropertiesData.find(
        (row) => row.uuid && row.uuid.toLowerCase() === lowerCaseOfferTokenAddress
      );
      return matchingRow ? parseFloat(matchingRow.netRentYearPerToken) : NaN;
    },
    [realTokenPropertiesData]
  );

  // New function to fetch JSON data instead of CSV
  const fetchRealTokenProperties = useCallback(async () => {
    const jsonUrl = process.env.PUBLIC_URL + '/realt-gnosis.json';
    return fetch(jsonUrl)
      .then(response => response.json())
      .then(data => {
        // Assuming the JSON file is an array of property objects
        return data;
      })
      .catch(error => {
        console.error('Error fetching JSON data:', error);
      });
  }, []);

  useEffect(() => {
    const fetchDataAndParseJSON = async () => {
      const fetchedRealTokenProperties = await fetchRealTokenProperties();
      setRealTokenPropertiesData(fetchedRealTokenProperties);
      setDataFetched(true);
    };

    fetchDataAndParseJSON();
  }, [fetchRealTokenProperties]);

  const endpoint = 'https://api.thegraph.com/subgraphs/name/jycssu-com/yam-history-gnosis';
  const client = new GraphQLClient(endpoint);

  async function getOffersBySeller(sellerAddress) {
    const sellerOffersQuery = `
      query FindSellerOffers($sellerAddress: String!) {
        offers(
          where: { maker: $sellerAddress 
            quantity_gt: 0
            isActive: true
          }
          orderBy: createdAtTimestamp
          orderDirection: desc
        ) {
          id
          maker {
            id
          }
          taker {
            id
          }
          price
          quantity
          buyerToken {
            id
          }
          offerToken {
            id
          }
        }
      }
    `;
    const variables = { sellerAddress: sellerAddress.trim().toLowerCase() };
    const data = await client.request(sellerOffersQuery, variables);
    return data.offers;
  }

  const onSellerClick = async (sellerAddress) => {
    try {
      const sellerOffers = await getOffersBySeller(sellerAddress);
      setSellerOffersData(sellerOffers);
    } catch (error) {
      console.error('Failed to fetch offers for the seller:', error);
    }
  };

  const onPropertyClick = (propertyData, type, offerTokenAddress) => {
    if (!offerTokenAddress) {
      console.error("offerTokenAddress is undefined");
      return;
    }
    setselectedTokenAddress(offerTokenAddress);
  };

  const web3 = useMemo(() => {
    return new Web3("https://rpc.gnosischain.com");
  }, []);

  const yamAddress = "0xC759AA7f9dd9720A1502c104DaE4F9852bb17C14";
  const yamAbi = useMemo(() => YamABIArray, []);
  const yamContract = useMemo(() => {
    return new web3.eth.Contract(yamAbi, yamAddress);
  }, [web3.eth.Contract, yamAbi]);

  const swapCatAddress = "0xB18713Ac02Fc2090c0447e539524a5c76f327a3b";
  const playSound = () => {
    if (soundOn) {
      const audio = new Audio('/catMeow.mp3');
      audio.play();
    }
  };

  const formatTransferEventMessage = useCallback(async (transferEvent, currentBlockTimestamp) => {
    const { from, to, value, tokenAddress } = transferEvent;
    const timestamp = new Date(currentBlockTimestamp * 1000)
      .toUTCString()
      .replace(/ GMT$/, "");
    const shortFromAddress = `${from.slice(0, 6)}...${from.slice(-4)}`;
    const shortToAddress = `${to.slice(0, 6)}...${to.slice(-4)}`;
    const shortName = getShortNameFromCSV(tokenAddress);

    const tokenPrice = await getTokenPriceFromCSV(tokenAddress);
    const netRentYearPerToken = await getNetRentYearPerTokenFromCSV(tokenAddress);
    const tokenYield = ((netRentYearPerToken / tokenPrice) * 100).toFixed(2);

    return {
      time: timestamp,
      type: "TRANSFER",
      seller: {
        address: from,
        shortAddress: shortFromAddress,
      },
      buyer: {
        address: to,
        shortAddress: shortToAddress,
      },
      market: "NA",
      id: "NA",
      property: shortName,
      offerTokenAddress: tokenAddress,
      quantity: parseFloat(web3.utils.fromWei(value)).toFixed(2),
      price: parseFloat(tokenPrice).toFixed(2),
      pDiff: "0.00",
      offerYield: tokenYield,
      yieldDiff: "0.00",
    };
  }, [web3.utils, getShortNameFromCSV, getNetRentYearPerTokenFromCSV, getTokenPriceFromCSV]);

  const formatYamEventMessage = useCallback(async (event) => {
    const { offerId, offerToken, buyerToken, seller, price, amount } = event.returnValues;
    const block = await web3.eth.getBlock(event.blockNumber);
    const timestamp = new Date(block.timestamp * 1000)
      .toUTCString()
      .replace(/ GMT$/, "");
    const shortSellerAddress = `${seller.slice(0, 6)}...${seller.slice(-4)}`;
    const shortName = getShortNameFromCSV(offerToken);

    const tokenPrice = await getTokenPriceFromCSV(offerToken);
    const netRentYearPerToken = await getNetRentYearPerTokenFromCSV(offerToken);

    const offerPrice = buyerToken === "0xe91D153E0b41518A2Ce8Dd3D7944Fa863463a97d" 
      ? Number(web3.utils.fromWei(price)) 
      : Number(web3.utils.fromWei(price, "Mwei"));
    const pDiff = offerPrice - tokenPrice;
    const offerYield = ((netRentYearPerToken / offerPrice) * 100).toFixed(2);
    const tokenYield = ((netRentYearPerToken / tokenPrice) * 100).toFixed(2);
    const yieldDiff = (offerYield - tokenYield).toFixed(2);
    return {
      time: timestamp,
      type: "NEW",
      seller: {
        address: seller,
        shortAddress: shortSellerAddress,
      },
      buyer: {
        address: buyerToken,
        shortAddress: `${buyerToken.slice(0, 6)}...${buyerToken.slice(-4)}`,
      },
      market: "YAM",
      id: offerId,
      property: shortName,
      offerTokenAddress: offerToken,
      quantity: parseFloat(web3.utils.fromWei(amount)).toFixed(2),
      price: buyerToken === "0xe91D153E0b41518A2Ce8Dd3D7944Fa863463a97d" 
        ? parseFloat(web3.utils.fromWei(price)).toFixed(2) 
        : parseFloat(web3.utils.fromWei(price, "Mwei")).toFixed(2),
      pDiff: pDiff.toFixed(2),
      offerYield: offerYield,
      yieldDiff: yieldDiff,
    };
  }, [web3.eth, web3.utils, getShortNameFromCSV, getNetRentYearPerTokenFromCSV, getTokenPriceFromCSV]);

  const processYamOfferCreatedEvent = async (event) => {
    const data = await formatYamEventMessage(event);
    setItems((prevItems) => [
      {
        ...data,
        type: 'NEW',
      },
      ...prevItems,
    ]);
    setYamOffersLast24Hours((prev) => prev + 1);
    playSound();
  };

  const formatSwapCatEventMessage = async (transaction) => {
    const { input, blockNumber } = transaction;
    const decodedInput = web3.eth.abi.decodeParameters(
      ["address", "address", "uint256", "uint24"],
      input.slice(10)
    );
    const block = await web3.eth.getBlock(blockNumber);
    const timestamp = new Date(block.timestamp * 1000)
      .toUTCString()
      .replace(/ GMT$/, "");

    const shortOfferTokenAddress = `${decodedInput[0].slice(0, 6)}...${decodedInput[0].slice(-4)}`;
    const shortBuyerTokenAddress = `${decodedInput[1].slice(0, 6)}...${decodedInput[1].slice(-4)}`;
    const shortName = getShortNameFromCSV(decodedInput[0]);

    const tokenPrice = await getTokenPriceFromCSV(decodedInput[0]);
    const netRentYearPerToken = await getNetRentYearPerTokenFromCSV(decodedInput[0]);

    const offerPrice = parseFloat(web3.utils.fromWei(decodedInput[2])).toFixed(2);
    const pDiff = offerPrice - tokenPrice;
    const offerYield = ((netRentYearPerToken / offerPrice) * 100).toFixed(2);
    const tokenYield = ((netRentYearPerToken / tokenPrice) * 100).toFixed(2);
    const yieldDiff = (offerYield - tokenYield).toFixed(2);

    return {
      time: timestamp,
      type: "NEW",
      seller: {
        address: decodedInput[0],
        shortAddress: shortOfferTokenAddress,
      },
      buyer: {
        address: decodedInput[1],
        shortAddress: shortBuyerTokenAddress,
      },
      market: "SWAPCAT",
      id: decodedInput[3],
      property: shortName,
      offerTokenAddress: decodedInput[0],
      price: offerPrice,
      pDiff: pDiff.toFixed(2),
      offerYield: offerYield,
      yieldDiff: yieldDiff,
    };
  };

  const processSwapCatMakeOfferFunction = async (transaction) => {
    const { input } = transaction;
    const makeOfferFunctionSignature = web3.eth.abi.encodeFunctionSignature("makeoffer(address,address,uint256,uint24)");
    if (input.startsWith(makeOfferFunctionSignature)) {
      const data = await formatSwapCatEventMessage(transaction);
      setItems((prevItems) => [
        {
          ...data,
          type: 'NEW',
        },
        ...prevItems,
      ]);
      playSound();
    }
  };

  const formatYamAcceptedEventMessage = useCallback(async (event) => {
    const { returnValues } = event;
    const block = await web3.eth.getBlock(event.blockNumber);
    const timestamp = new Date(block.timestamp * 1000)
      .toUTCString()
      .replace(/ GMT$/, "");
    const shortSellerAddress = `${returnValues.seller.slice(0, 6)}...${returnValues.seller.slice(-4)}`;
    const shortName = getShortNameFromCSV(returnValues.offerToken);

    return {
      time: timestamp,
      type: "SOLD",
      seller: {
        address: returnValues.seller,
        shortAddress: shortSellerAddress,
      },
      buyer: {
        address: returnValues.buyer,
        shortAddress: `${returnValues.buyer.slice(0, 6)}...${returnValues.buyer.slice(-4)}`,
      },
      market: "YAM",
      id: returnValues.offerId,
      property: shortName,
      offerTokenAddress: returnValues.offerToken,
      quantity: parseFloat(web3.utils.fromWei(returnValues.amount)).toFixed(2),
      price:
        returnValues.buyerToken === "0xe91D153E0b41518A2Ce8Dd3D7944Fa863463a97d"
          ? parseFloat(web3.utils.fromWei(returnValues.price)).toFixed(2)
          : parseFloat(web3.utils.fromWei(returnValues.price, "Mwei")).toFixed(2),
      pDiff: 0,
      offerYield: 0,
      yieldDiff: 0,
    };
  }, [web3, getShortNameFromCSV]);

  const processYamOfferAcceptedEvent = async (event) => {
    const data = await formatYamAcceptedEventMessage(event);
    setItems((prevItems) => [
      {
        ...data,
        type: 'SOLD',
      },
      ...prevItems,
    ]);
    playSound();
  };

  const fetchPastEvents = useCallback(async () => {
    if (hasFetchedPastEventsRef.current) {
      return;
    }
    hasFetchedPastEventsRef.current = true;

    const currentBlock = await web3.eth.getBlock("latest");
    const last24HoursBlock = currentBlock.number - (24 * 60 * 60 / 5); // Assuming 5 seconds per block

    const yamOfferCreatedEvents = await yamContract.getPastEvents("OfferCreated", {
      fromBlock: last24HoursBlock,
      toBlock: "latest",
    });

    const yamOfferAcceptedEvents = await yamContract.getPastEvents("OfferAccepted", {
      fromBlock: last24HoursBlock,
      toBlock: "latest",
    });

    setYamOffersLast24Hours(yamOfferCreatedEvents.length);

    console.log("I should only be called once per refresh in browser!");

    const newItems = await Promise.all(
      yamOfferCreatedEvents.map(async (event) => {
        const eventData = await formatYamEventMessage(event);
        return { type: 'NEW', ...eventData };
      })
    );

    const acceptedItems = await Promise.all(
      yamOfferAcceptedEvents.map(async (event) => {
        const eventData = await formatYamAcceptedEventMessage(event);
        return { type: 'SOLD', ...eventData };
      })
    );

    setItems((prevItems) => [
      ...prevItems,
      ...newItems,
      ...acceptedItems,
    ]);
  }, [
    web3.eth,
    yamContract,
    formatYamEventMessage,
    formatYamAcceptedEventMessage,
  ]);

  useEffect(() => {
    if (!isInitiatedRef.current) {
      isInitiatedRef.current = true;
      fetchRealTokenProperties()
        .then(() => {
          setDataFetched(true);
        })
        .catch((error) => {
          console.error('Failed to fetch real token properties:', error);
        });
    }

    if (dataFetched && realTokenPropertiesData.length > 0) {
      fetchPastEvents();
    }
  }, [fetchRealTokenProperties, dataFetched, fetchPastEvents, realTokenPropertiesData]);

  useEffect(() => {
    const intervalId = setInterval(async () => {
      const currentBlock = await web3.eth.getBlock("latest");
      setCurrentBlockNumber(currentBlock.number);

      currentBlock.transactions.forEach(async (tx) => {
        if (processedTransactions.has(tx)) {
          return;
        }

        const txReceipt = await web3.eth.getTransactionReceipt(tx);
        if (txReceipt && txReceipt.to) {
          if (String(txReceipt.to).toLowerCase() === yamAddress.toLowerCase()) {
            const offerCreatedEvents = await yamContract.getPastEvents("OfferCreated", {
              filter: { transactionHash: tx },
              fromBlock: currentBlock.number,
              toBlock: currentBlock.number,
            });

            if (offerCreatedEvents.length > 0) {
              processYamOfferCreatedEvent(offerCreatedEvents[0]);
            }

            const offerAcceptedEvents = await yamContract.getPastEvents("OfferAccepted", {
              filter: { transactionHash: tx },
              fromBlock: currentBlock.number,
              toBlock: currentBlock.number,
            });

            if (offerAcceptedEvents.length > 0) {
              processYamOfferAcceptedEvent(offerAcceptedEvents[0]);
            }
          } else if (String(txReceipt.to).toLowerCase() === swapCatAddress.toLowerCase()) {
            const transaction = await web3.eth.getTransaction(tx);
            processSwapCatMakeOfferFunction(transaction);
          } else {
            // Check if the 'to' address matches any of the uuid addresses in our properties data
            const lowerCaseToAddress = String(txReceipt.to).toLowerCase();
            const matchingRow = realTokenPropertiesData.find(
              (row) =>
                row.uuid && row.uuid.toLowerCase() === lowerCaseToAddress
            );

            if (matchingRow) {
              const tokenContract = new web3.eth.Contract(erc20Abi, txReceipt.to);
              const transferEvents = await tokenContract.getPastEvents("Transfer", {
                filter: { transactionHash: tx },
                fromBlock: currentBlock.number,
                toBlock: currentBlock.number,
              });

              if (transferEvents.length > 0) {
                const transferEvent = {
                  from: transferEvents[0].returnValues.from,
                  to: transferEvents[0].returnValues.to,
                  value: transferEvents[0].returnValues.value,
                  tokenAddress: txReceipt.to,
                };
                const eventData = await formatTransferEventMessage(transferEvent, currentBlock.timestamp);
                setItems((prevItems) => [eventData, ...prevItems]);
              }
            }
          }

          setProcessedTransactions((prev) => new Set([...prev, tx]));
        } else {
          console.error("Transaction or 'to' property is not defined");
        }
      });
    }, 3000);
    return () => clearInterval(intervalId);
  });

  return (
    <>
      <Header
        currentBlockNumber={currentBlockNumber}
        yamOffersLast24Hours={yamOffersLast24Hours}
        soundEnabled={soundOn}
        toggleSound={() => setSoundOn(!soundOn)}
        activeView={activeView}
        setActiveView={setActiveView}
      />
      <div className="content-container">
        {activeView === 'liveMarket' ? (
          <OffersTable
            data={items}
            onPropertyClick={onPropertyClick}
            onSellerClick={onSellerClick}
            getShortNameFromCSV={getShortNameFromCSV}
          />
        ) : activeView === 'marketExplorer' ? (
          <MarketExplorerView
            realTokenProperties={realTokenPropertiesData}
            onPropertyClick={onPropertyClick}
          />
        ) : (
          <AllOffersView
            realTokenPropertiesData={realTokenPropertiesData}
          />
        )}

        <DetailsView          
          propertyTokenAddress={selectedTokenAddress}
          sellerOffers={sellerOffersData ? sellerOffersData : null}
          realTokenProperties={realTokenPropertiesData}
        />
      </div>
      <Footer />
    </>
  );
}

export default App;
